import React from "react";
import Layout from "../../components/Layout";

export default function revistas() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_salud/3.jpg)`}}
                    >
                        <h1>Revistas</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Títulos con amplia visibilidad entre la comunidad científica internacional, indexados en las
                            principales bases de datos especializadas en ciencia, tecnología y medicina.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}